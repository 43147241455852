const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    paddingBottom: "0",
    border: "none",
    background: "transparent",
    pointerEvent: 'none',
    margin: "0 !important",
    "& .MuiAccordionDetails-root": {
      padding: 0
    },
    "&::before": {
      content: "none"
    },
    "& h3 > *": {
      margin: "0 !important"
    },
    '&:hover:not(.Mui-disabled)': {
      cursor: 'default',
    },
    "& .MuiAccordionSummary-content": {
      cursor: 'default',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 32px',
    height: "64px",
    margin: "0 !important",
    "& .MuiAccordionSummary-content": {
      margin: "0 !important",
    },
    cursor: 'default',
  },
  content: {
    display: "flex",
    gap: "32px",
    paddingBottom: "16px",
    width: '100%',
  },
  item: {
    borderRadius: "16px",
    height: "112px",
    padding: "32px",
    boxSizing: "border-box",
    backgroundColor: "#FFFFFF",
    width: "100%",
    boxShadow: "0px 0px 16px 0px #E5E5E5",
    display: "flex",
    flexDirection: "row",
    justifyContent: 'space-between',
  },
  subtitle: {
    fontSize: '14px',
    fontWeight: '400',
    color: "#5F5F5F"
  },
  title: {
    fontSize: "16px",
    fontWeight: "500",
  },
  swiper:{
    width: 'calc(99vw - 32px)',
    ' .swiper-wrapper':{
      marginBottom:"50px"
    },
    ' .swiper':{
      height:"180px"
    },
    '.swiper-pagination':{
      bottom:'',
      top: "80% !important",

    },
    '.swiper-pagination::before':{
      content: '""',
      position:"absolute",
      backgroundColor:"white",
      top: "-12px",
      left:"calc(50% - 56px)",
      zIndex:"-1",
      height:"48px",
      width: "112px !important",
      borderRadius: "30px"
    },
    ' .swiper-pagination-bullet-active':{
      transition: 'all 0.3s ease-in-out',
      width:"16px",
      backgroundColor:"black",
      borderRadius:"8px"
    }
  },
  card: {
    display: "flex",
    gap: "16px",
    alignItems: "center",
  },
  decorative: {
    height: "24px",
    backgroundColor: "#2D2D2D",
    width: "8px",
    borderRadius: "32px",
  }
}

export default styles