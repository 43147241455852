import {updateQrStatus} from "./slices/qr";
import {Dispatch} from "react";
import {UnknownAction} from "@reduxjs/toolkit";

type QrCodeMessage = {
  event: 'QR_READY',
  product_id: string,
  product_name: string,
  qr_code_id: string,
  remote_archive_paths: string[]
}

type QrBalanceMessage = {
  event: 'CHANGE_BALANCE',
  balance: number,
}

type SocketMessageData = QrCodeMessage | QrBalanceMessage

export class BestWebSocket {
  private readonly url: string;
  private socket: WebSocket | null = null;
  private dispatch: any;
  private reconnectAttempts: number = 0;
  private maxReconnectAttempts: number = 5;
  private reconnectDelay: number = 1000; // 1 секунда
  public isConnected= false; // Флаг при успешном подключении

  constructor(url: string) {
    this.url = url;
  }

  // Инициализация WebSocket с доступом к Redux dispatch
  public init(dispatch: Dispatch<UnknownAction>): void {
    if (this.isConnected) {
      return;
    } else {
      this.dispatch = dispatch;
      this.connect();
    }
  }

  // Подключение к серверу
  private connect(): void {
    this.socket = new WebSocket(this.url);

    this.socket.onopen = () => {
      this.isConnected = true; // Устанавливаем флаг при успешном подключении
      console.log('Connected to server');
      this.reconnectAttempts = 0; // Сброс счетчика попыток переподключения
    };

    this.socket.onmessage = (event: MessageEvent) => {
      const data: SocketMessageData = JSON.parse(event.data);
      console.log(data)

      if (data.event === 'QR_READY') {
        this.dispatch(updateQrStatus({
          qr_code_id: data.qr_code_id,
          productId: data.product_id,
          productName: data.product_name,
          updatedFields: {
            link: data.remote_archive_paths
          }
        }));
      }
    };

    this.socket.onclose = () => {
      console.log('Disconnected from server');
      this.isConnected = false;
      this.attemptReconnect();
    };

    this.socket.onerror = (error: Event) => {
      console.error('WebSocket error:', error);
    };
  }

  // Отправка события
  public emit<T = any>(event: string, payload: T): void {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      const message = JSON.stringify({ event, payload });
      this.socket.send(message);
    } else {
      console.warn('WebSocket is not open. Message not sent.');
    }
  }

  // Автоматическое восстановление соединения
  private attemptReconnect(): void {
    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      this.reconnectAttempts++;
      console.log(`Attempting to reconnect (${this.reconnectAttempts}/${this.maxReconnectAttempts})...`);
      setTimeout(() => this.connect(), this.reconnectDelay);
    } else {
      console.error('Max reconnection attempts reached. Giving up.');
    }
  }

  // Отключение
  public disconnect(): void {
    console.log('disconnect socket')
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
  }
}