const styles = {
  overlay: {
    height: '100vh',
    width: '100vw',
    backgroundColor: "rgba(255, 255, 255, 0.64)",
    position: 'fixed',
    top: 0,
    zIndex: 100,
    backdropFilter: 'blur(24px)',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    overflow: 'hidden',
  },

  container: {
    maxWidth: '532px',
    width: '100%',
    padding: "32px",
    background: "#FFFFFF",
    borderRadius: "16px",
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    boxShadow: "0px 0px 16px 0px #E5E5E5",
    boxSizing: 'border-box',
    '@media(max-width:1024px)':{
      padding:'16px'
    }
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buttons: {
    paddingTop: '8px',
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
  },
  subtitle: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.01em",
    color: "#5F5F5F",
  },
  content:{
    padding: "0 32px",
    display: "flex" ,
    flexDirection: 'column',
    gap: '32px',
    '@media(max-width:1024px)':{
      padding: "0",
    }
  }
} as const

export default styles