import {Box, Link, Typography} from '@mui/material'
import {LabelUI} from '../../ui'
import IconUI from '../../ui/icon'
import styles from './styles'
import {icon_white, icons, info} from './constants'
import {BASE_URL} from "../../../api/Service";

interface Props {
  isAuth?: boolean
}

const Footer = ({isAuth = false}: Props) => {
  const screenWidth = window.innerWidth

  return (
      <footer
          style={{
            position: 'relative',
            zIndex: '2',
            marginTop: 'auto'
          }}
      >
        {
          isAuth ? (
              <Box sx={{
                '@media (max-width: 624px)': {
                  padding: '32px'
                },
                '@media (max-width: 1024px)': {
                  padding: '36px 64px',
                  gap: '32px'
                },
                padding: '40px 150px',
                backgroundColor: '#000',
                display: 'flex',
                flexDirection: 'column',
                gap: '64px'
              }}>
                {/* Logo & Links (> 1024px) */}
                <Box sx={{
                  display: 'flex',
                  '@media (max-width: 1024px)': {
                    justifyContent: 'center'
                  },
                  justifyContent: 'space-between'
                }}>
                  <Link href={`${BASE_URL}/home/dashboard`}>
                    <img src={'/assets/logo_white.svg'} alt={'logo'}/>
                  </Link>

                  <Box sx={{
                    '@media (max-width: 1024px)': {
                      display: 'none'
                    },
                    display: 'flex',
                    gap: '32px',
                    alignItems: 'center'
                  }}>
                    <Link href="https://iqtag.io/#how-works" target="_blank"
                            rel="noopener noreferrer" sx={styles.link}>
                      How It Works
                    </Link>
                    <Link href="https://iqtag.io/#security" target="_blank"
                            rel="noopener noreferrer" sx={styles.link}>
                      Security
                    </Link>
                    <Link href="https://iqtag.io/#advantages" target="_blank"
                            rel="noopener noreferrer" sx={styles.link}>
                      Advantages
                    </Link>
                    <Link href="https://iqtag.io/#contacts" target="_blank"
                            rel="noopener noreferrer" sx={styles.link}>
                      Contacts
                    </Link>
                  </Box>
                </Box>

                <Box sx={{
                  '@media (max-width: 1024px)': {
                    flexDirection: 'column',
                    alignItems: 'start'
                  },
                  display: 'flex',
                  gap: '32px',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}>
                  <Box sx={{
                    '@media (max-width: 1024px)': {
                      flexDirection: 'column',
                      alignItems: 'start'
                    },
                    display: 'flex',
                    gap: '32px',
                    alignItems: 'center'
                  }}>
                    {
                      info && info.map((item, index) => {
                        return (
                          <Box key={index} sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
                            <IconUI name={item.icon} />
                            {item.icon === "mail" ? (
                              <Typography
                                variant="subtitle1"
                                component="a"
                                href={`mailto:${item.text}`}
                                sx={{
                                  fontSize: '16px',
                                  fontWeight: '400',
                                  color: '#FFFFFF',
                                  textDecoration: 'none', 
                                }}
                              >
                                {item.text}
                              </Typography>
                            ) : (
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  fontSize: '16px',
                                  fontWeight: '400',
                                  color: '#FFFFFF',
                                }}
                              >
                                {item.text}
                              </Typography>
                            )}
                          </Box>
                        );
                      })
                    }

                  </Box>
                  {/* <ButtonUI
                    type={'outline'}
                    sx={{
                      '@media (max-width: 1024px)': {
                        margin: '0 auto',
                      },
                      color: '#FFFFFF',
                      borderColor: '#FFFFFF',
                    }}
                    text={'Write to us'}
                    onClick={() => window.open('https://iqtag.io/#contacts', '_blank', 'noopener noreferrer')}
                  /> */}

                </Box>

                <Box sx={{height: '1px', width: '100%', backgroundColor: '#2D2D2D'}}/>

                {/* Social, privacy, copyright */}
                <Box sx={{display: 'flex', gap: '32px', flexDirection: 'column'}}>
                  <Box sx={{
                    '@media (max-width: 624px)': {
                      display: 'flex',
                      flexDirection: 'column-reverse',
                      justifyContent: 'center'
                    },
                    display: 'grid',
                    gridTemplateColumns: '1fr auto auto',
                    alignItems: 'center',
                    gap: '32px'
                  }} m={0}>

                    <Typography variant="subtitle1" sx={{fontSize: '14px', fontWeight: '400', color: '#D8D8D8'}}>
                      © 2024-2025 IQTAG. All rights reserved
                    </Typography>

                    <Box sx={{
                      '@media (max-width: 624px)': {
                        gridTemplateColumns: 'auto',
                        gridAutoFlow: 'revert',
                        justifyContent: 'center'
                      },
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '32px'
                    }}>
                      <Link 
                            href="https://drive.google.com/file/d/1vqxSqZEuH-9x94fYllw3ZFchjI-5_uB_/view?usp=drive_link"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={styles.link}
                          >
                            Terms of Use
                          </Link>
                      <Link href="https://drive.google.com/file/d/17jLxRIJ8dSEG8JEvt32RSN-hPiopELcj/view?usp=drive_link"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={styles.link}>
                        Privacy Policy
                      </Link>
                    </Box>

                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                      {icon_white.map((social, index) =>
                          <Link
                              key={index}
                              sx={{
                                display: 'inline-flex',
                                padding: '0 8px'
                              }}
                              href={social.link}
                              target="_blank"
                              rel="noopener noreferrer"
                          >
                            {social.tag}
                          </Link>
                      )}
                    </Box>
                  </Box>
                </Box>

              </Box>
          ) : (
              <>
                {
                  screenWidth > 624
                      ? (
                          <Box sx={styles.not_auth_footer}>

                            <LabelUI text="© 2024-2025 IQTAG. All rights reserved"/>
                            <Box sx={styles.not_auth_footer_links}>
                              <Link
                                  href="https://drive.google.com/file/d/1vqxSqZEuH-9x94fYllw3ZFchjI-5_uB_/view?usp=drive_link"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  sx={styles.not_auth_link}
                              >
                                Terms of Use
                              </Link>

                              <Link
                                  href="https://drive.google.com/file/d/17jLxRIJ8dSEG8JEvt32RSN-hPiopELcj/view?usp=drive_link"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  sx={styles.not_auth_link}>
                                Privacy Policy
                              </Link>

                              <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                {icons.map((social, index) =>
                                    <Link
                                        key={index}
                                        sx={{
                                          display: 'inline-flex',
                                          padding: '0 8px'
                                        }}
                                        href={social.link}
                                           target="_blank"
                            rel="noopener noreferrer"
                                    >
                                      {social.tag}
                                    </Link>
                                )}
                              </Box>
                            </Box>
                          </Box>
                      )
                      : (
                          <Box sx={styles.not_auth_footer}>

                            <Box sx={styles.not_auth_footer_links}>
                              <Box sx={styles.not_auth_footer_underline_links}>
                                <Link
                                    href="https://drive.google.com/file/d/1vqxSqZEuH-9x94fYllw3ZFchjI-5_uB_/view?usp=drive_link"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={styles.not_auth_link}
                                >
                                  Terms of Use
                                </Link>

                                <Link
                                    href="https://drive.google.com/file/d/17jLxRIJ8dSEG8JEvt32RSN-hPiopELcj/view?usp=drive_link"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={styles.not_auth_link}>
                                  Privacy Policy
                                </Link>
                              </Box>

                              <Box sx={styles.not_auth_icons}>
                                {icons.map((social, index) =>
                                    <Link
                                        key={index}
                                        sx={{
                                          display: 'inline-flex',
                                          padding: '0 8px'
                                        }}
                                        href={social.link}
                                           target="_blank"
                            rel="noopener noreferrer"
                                    >
                                      {social.tag}
                                    </Link>
                                )}
                              </Box>
                            </Box>
                            <LabelUI text="© 2024-2025 IQTAG. All rights reserved"/>
                          </Box>
                      )
                }

              </>
          )
        }
      </footer>
  )
}

export default Footer