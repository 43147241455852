import React from "react";
import {Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis,} from "recharts";

type Props = {
  data: Array<{ name: string; uv: number }>;
  color: string;
  type: 'scanned' | 'verified';
};

const CustomActiveDot = ({ cx, cy, color}: any) => {
  if (!cx || !cy) return null;

  return (
      <g>
        <circle cx={cx} cy={cy} r={5} fill={color} />
        <circle cx={cx} cy={cy} r={10} fill="none" stroke={color} strokeWidth={2} />
      </g>
  );
};

const CustomTooltip = ({ active, payload, color, type}: any) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload; // Данные точки
    return (
        <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              backgroundColor: "white",
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
        >
          <div style={{height: '24px', width: '8px', borderRadius: '32px', background: color}}></div>
          <div>
            <p style={{margin: 0, color: "#2D2D2D", fontWeight: "500"}}>{data.uv}</p>
            <p style={{margin: 0, color: "#5F5F5F"}}>
              {type === 'scanned'
                  ? 'Scanned'
                  : 'Verified'
              } : {data.name}
            </p>
          </div>
        </div>
    );
  }

  return null;
};

const AnalyticChart = ({data, color, type}: Props) => {
  return (
      <ResponsiveContainer
          width={'100%'}
          height={300}
          style={{padding: '0 32px 0 0'}}
      >
        <AreaChart
            height={300}
            data={data}
            margin={{bottom: 32,  top:10, right:15}}
        >
          <defs>
            <linearGradient id="gradientColor" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" style={{ stopColor: color, stopOpacity: 0.5 }} />
              <stop offset="100%" style={{ stopColor: color, stopOpacity: 0 }} />
            </linearGradient>
          </defs>
          <XAxis
              dataKey="name"
              strokeWidth={0}
              color="#5F5F5F"
              tickMargin={32}
              fontSize={14}
          />
          <YAxis
              dataKey={"uv"}
              strokeWidth={0}
              width={100}
              color="#5F5F5F"
              tickMargin={32}
              fontSize={14}
              tickFormatter={(value) => (Number.isInteger(value) ? value : '')}
          />
          <Tooltip
              cursor={{ strokeDasharray: "3 3", stroke: "orange", strokeWidth: 1 }}
              content={<CustomTooltip color={color} type={type}/>}
          />
          <Area
              type="monotone"
              dataKey="uv"
              stroke={color}
              strokeWidth={2}
              fill="url(#gradientColor)"
              activeDot={(props) => <CustomActiveDot color={color} {...props} />}
          />
        </AreaChart>
      </ResponsiveContainer>
  );
};

export default AnalyticChart;
