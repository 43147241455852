import {Dispatch, SetStateAction, useEffect, useState} from "react";
import styles from "./styles";
import {Box, Typography,} from "@mui/material";
import {ButtonUI, InputUI, LabelUI} from "../../../ui";
import {getProduct} from "../../../../app/slices/products";
import {addCustomField} from "../../../../app/slices/field";
import {Field, Form, Formik} from "formik";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import * as yup from "yup";

interface Props {
  setOpenModal: (openModal: boolean) => void;
  productId: string | undefined,
  setUpdateFields: Dispatch<SetStateAction<boolean>>,
  index: string,
  canCreateButton: boolean,
}

interface SelectDataItem {
  value: string,
  label: string
}

const urlRegex = /^(https?:\/\/|ftp:\/\/)?(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}|localhost)(:[0-9]{1,5})?(\/[^\s]*)?$/;
const fieldSchema = yup.object().shape({
  link: yup.string()
      .test('is-url', 'Invalid URL', (value) => {
        if (!value) return true;
        return urlRegex.test(value);
      }),
});

const AddNewField = ({
  setOpenModal,
  productId,
  setUpdateFields,
  index,
  canCreateButton
}: Props) => {
  const [selectData, setSelectData] = useState<SelectDataItem[]>([
    { value: "field", label: "[FIELD]" },
    { value: "button", label: "[BUTTON]" },
    { value: "textarea", label: "[TEXTAREA]" },
  ]);
  const dispatch = useDispatch();
  const { t } = useTranslation()

  const submitHandler = async (values: any) => {
    try {
      // @ts-ignore
      dispatch(addCustomField({
            productId,
            ...values,
            index
          })
      ).then(() => setOpenModal(false))
          .finally(() => {
            // @ts-ignore
            dispatch(getProduct(productId))
            setUpdateFields(true)
          })

    } catch (e: any) {
      console.log(e);
    }
  };

  useEffect(() => {
    setSelectData(
        canCreateButton
            ? selectData
            : selectData.filter((item) => item.value !== "button")
    )
  }, [canCreateButton])

  return (
    <Formik
      initialValues={{
        type: "field",
        title: "",
        description: "",
        content: "",
        link: "",
      }}
      validationSchema={fieldSchema}
      onSubmit={submitHandler}
    >
    {({ errors, touched, values, handleSubmit, setFieldValue, setErrors }) => (
      <Form style={{
        width: '100%',
        maxWidth: '532px',
      }}>
        <Box
            sx={styles.container}
            onClick={(e) => {e.stopPropagation()}}
        >
          <Box sx={styles.header}>
            <Typography variant="h6">Add new field</Typography>
            <img
              src="/assets/icons/modal_close.svg"
              onClick={() => setOpenModal(false)}
              alt={''}
              style={{cursor: 'pointer'}}
            />
          </Box>

          <Box sx={styles.content}>
            <InputUI
              label="Type"
              select
              selectData={selectData}
              value={values.type}
              selectHandleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('type', event.target.value)
                setFieldValue('link', '')
                setFieldValue('content', '')
                setErrors({link: '', content: ''})
              }}
            />

            <InputUI label={t("title")} value={values.title} onChange={
              (event: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('title',event.target.value)}
            />

            {values.type === "textarea" ? (
              <Box sx={{display: 'flex'}}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('description',event.target.value)}
              >
                <InputUI label={t("description")} value={values.description} multiline />
              </Box>
            ) : (
              <Box sx={{display: 'flex', position: 'relative', flexDirection: 'column'}}>
                <Box sx={{ padding: '0 16px 16px', display: 'flex', justifyContent: 'space-between' }}>
                  <LabelUI
                      htmlFor={values.type === 'button' ? "link" : "content"}
                      text={values.type === 'button' ? "Link" : "Content"}
                      error={errors.link && touched.link && values.type === 'button'}
                  />
                </Box>
                <Field
                    name={values.type === 'button' ? "link" : "content"}
                    style={{
                      border: errors.link && touched.link ? "1px solid #FF6954" : "1px solid #D8D8D8",
                      borderRadius: "10px",
                      padding: "8px 16px",
                      position: "relative",
                      width: "100%",
                      fontWeight: '400',
                      fontSize: '1rem',
                      boxSizing: "border-box",
                      height: "50px",
                    }}
                    value={values.type === 'button' ? values.link : values.content}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue(values.type === 'button' ? "link" : "content", e.target.value)
                    }
                />
                {(errors.link && touched.link) &&  (
                    <Typography
                        variant="caption" sx={{ color: "#FF6954", fontSize: "14px", position: 'absolute', marginLeft: '10px', bottom: '-30px' }}>
                      {errors.link}
                    </Typography>
                )}
              </Box>
            )}
          </Box>

          <Box sx={styles.buttons}>
            <ButtonUI
              type="outline"
              text={t("cancel")}
              onClick={() => setOpenModal(false)}
              width="114px"
            />

            <ButtonUI
              type="default"
              text={t("add")}
              onClick={handleSubmit}
              width="114px"
            />
          </Box>
        </Box>
      </Form>
    )}
    </Formik>
  );
};

export default AddNewField;
