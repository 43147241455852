import { Box, Tooltip } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography
} from "react-simple-maps";
import { csv } from "d3-fetch";
import { json } from "d3-fetch";
import useAnalyticsData from "../../../../shared/hooks/useAnalyticsData";

const geoUrl = "/features.json";

const CustomTooltip = ({ countryName, qrAmount, color, children, type}) => {
  return (
      <Tooltip
          title={
            <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                  backgroundColor: "white",
                  borderRadius: "8px",
                  padding: "10px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  height: '50px',
                }}
            >
              <div style={{height: '26px', width: '8px', borderRadius: '32px', background: color}}></div>
              <div>
                <p style={{margin: 0, color: "#2D2D2D", fontWeight: "500"}}>{type === 'scanned'
                    ? 'Scanned'
                    : 'Verified'
                } QR: {qrAmount}</p>
                <p style={{margin: 0, color: "#5F5F5F"}}>{countryName}</p>
              </div>
            </div>
          }
          sx={{
            "& .MuiTooltip-tooltip": {
              height: '100px',
            },
            "& .MuiTooltip-arrow": {
            }
          }}
          slotProps={{
            tooltip: {
              sx: {
                backgroundColor: "transparent",
                padding: 0,
                borderRadius: 0,
                boxShadow: "none",
                fontSize: '13px',
              }
            },
            arrow: {
              sx: {
                color: 'white',
                opacity: 1,
              }
            }
          }}
          arrow
      >
        {children}
      </Tooltip>

  );
};

const AnalyticMap = ({ place, type }) => {
  const [data, setData] = useState([]);
  const [geography, setGeography] = useState(null);

  const analyticsData = useAnalyticsData(type, place, true);

  useEffect(() => {
    json(geoUrl).then((geoData) => {
      setGeography(geoData);
    });
  }, []);

  useEffect(() => {
    csv(`/vulnerability.csv`).then((data) => {
      setData(data);
    });
  }, []);

  const maxScans = useMemo(() => {
    return Math.max(...Object.values(analyticsData || {}), 0);
  }, [analyticsData]);

  return (
      <Box sx={{ position: "relative", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <ComposableMap
            projectionConfig={{
              scale: 140,
              rotate: [-11, 0, 0],
              center: [0, 40]
            }}
            projection="geoMercator"
            style={{ width: "100%", height: "420px", margin: "0 auto", display: "block" }}
        >
          {geography && (
              <Geographies geography={geography}>
                {({ geographies }) =>
                    geographies.map((geo) => {
                      const countryId = geo.id;
                      const countryName = geo.properties.name || "Unknown";

                      let alpha = 0;
                      if (analyticsData && analyticsData[countryId] && maxScans > 0) {
                        alpha = analyticsData[countryId] / maxScans;
                        alpha = Math.max(alpha, 0.15);
                      } else {
                        const csvEntry = data.find((s) => s.ISO3 === countryId);
                        if (csvEntry && csvEntry["1995"]) {
                          alpha = +csvEntry["1995"];
                        }
                      }

                      const fillColor = alpha > 0
                          ? type === "scanned"
                              ? `rgba(255, 136, 0, ${alpha})`
                              : `rgba(0, 192, 106, ${alpha})`
                          : "#c7c7c7";

                      return (
                          <CustomTooltip
                              key={geo.id}
                              countryName={countryName}
                              qrAmount={analyticsData[countryId] || 0}
                              color={fillColor}
                              type={type}
                          >
                            <Geography
                                geography={geo}
                                fill={fillColor}
                            />
                          </CustomTooltip>
                      );
                    })
                }
              </Geographies>
          )}
        </ComposableMap>
      </Box>
  );
};

export default AnalyticMap;
