import IconUI from "../../../ui/icon";
import {Typography, Box} from "@mui/material";
import useLocalStorage from "../../../../shared/hooks/useLocalStorage";
import {useDispatch} from "react-redux";
import { setScrollToId } from "../../../../app/slices/qr";

interface ProductInfo {
  productId: string,
  productName: string,
  qrCodeId: string,
  notificationIsGet: boolean
}

interface ToastNotificationProps {
  type: "QR_READY",
  productInfo?: ProductInfo,
}

export const ToastNotification = ({type,productInfo }: ToastNotificationProps) => {
  const dispatch = useDispatch();
  const info = typesNotification[type](productInfo?.productName);
  const [_, setTab] = useLocalStorage("ProductInfoTab", 0);

  const handleClicks = {
    QR_READY: () => {
      setTab(4)
      dispatch(setScrollToId(productInfo?.qrCodeId))
      if(!window.location.href.includes('/home/products/'))
        window.location.href = `/home/products/${productInfo?.productId}`

      setTimeout(() => {
        dispatch(setScrollToId(''))
      }, 3000)
    }
  }

  return (
      <Box sx={{display: 'flex', gap: '8px', cursor: 'pointer'}} onClick={handleClicks[type]}>
        <IconUI name={info.icon}/>
        <Typography fontSize="14px" fontWeight="400" color="#2D2D2D">{info.text}</Typography>
      </Box>
  )
}

const typesNotification = {
  QR_READY: (productName: string | undefined) => ({
    text: (
        <>
          QR codes are ready on the product: <Typography component="span" fontWeight="500" fontSize="14px" >{productName || ''}</Typography>
        </>
    ),
    icon: 'check_color',
  }),
}