import { createSlice } from "@reduxjs/toolkit";
import {BestWebSocket} from "../BestWebSocket";

const initialState = {
  socket: null,
}

const socketSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    initSocket: (state) => {
      if(!state.socket)
        state.socket = new BestWebSocket(`wss://app.iqtag.io:8000/ws?token=${localStorage.getItem('access_token')}`);
    },
    closeSocket: (state) => {
      if(state.socket) {
        state.socket.disconnect();
        state.socket = null;
      }
    },
  },
});

export const { initSocket, closeSocket } = socketSlice.actions;
export default socketSlice.reducer