import {Box, Grid2, Typography} from "@mui/material"
import {ButtonUI, LabelUI} from "../../../../../../ui"
import {Field, Form, Formik} from "formik"
import * as yup from 'yup';
import {useDispatch, useSelector} from "react-redux"
import {getDashboard} from "../../../../../../../app/slices/dashboard";
import {removeRejectField, setKyb_2, setStatus} from "../../../../../../../app/slices/kyb"
import MuiPhoneInput from "../../../../../../ui/MuiPhoneInput/MuiPhoneInput";

import { StepProps } from "../../../../../../../shared/types/instances";
import {useTranslation} from "react-i18next";
import {tDouble} from "../../../../../../../shared/utils/translateFunc";

const validationSchema = yup.object().shape({
  email: yup.string().required(tDouble("field_empty")),
  phone: yup.string().required(tDouble("field_empty")),
  primary_contact_name: yup.string().required(tDouble("field_empty_2")),
  position: yup.string().required(tDouble("field_empty")),
});

const Step2 = ({ handleStepUp, handleStepDown }: StepProps) => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const { user } = useSelector((state: any) => state.dashboard)

  const company_id = useSelector((state: any) => state.dashboard.dashboard.Current_Company)
  const company = useSelector((state: any) => state.dashboard.company)
  const rejectedFields = useSelector((state: any) => state.kyb.rejectedFields || [])

  const { step_2, isRejected } = useSelector((state: any) => state.kyb)

  const checkState = () => {
    if (user && company) {
      return {
        email: user.email,
        phone: company.phone,
        primary_contact_name: company.primary_contact_name,
        position: company.position,
        code: 'UK',
      }
    } else {
      return step_2
    }
  }


  const submitHandler = async (values: any) => {
    try {
      // @ts-ignore
      dispatch(setKyb_2({ company_id, ...values })).then(() => handleStepUp())
      dispatch(setStatus('third_tier'))
    } catch (e: any) {
      console.log(e)
    }
  }
  const backHandler = () => {
    // @ts-ignore
    dispatch(getDashboard()).then(() => handleStepDown())
    dispatch(setStatus('first_tier'))
  }

  return (
      <Formik
          initialValues={checkState()}
          validationSchema={validationSchema}
          onSubmit={submitHandler}
          validateOnMount={isRejected}
      >

        {({ errors, touched, values, setFieldValue, handleSubmit }: any) => {
          return (
            <Form style={{ display: 'flex', flexDirection: 'column', gap: '32px', }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', }}>
                <Box sx={{ padding: '0 16px' }}>
                  <LabelUI
                      htmlFor="email"
                      text="Email"
                      error={errors.email && touched.email || rejectedFields.includes('email')} />
                </Box>

                <Field
                    name='email'
                    style={{
                      border: errors.email && touched.email || rejectedFields.includes('email') ? "1px solid #FF6954" : "1px solid #D8D8D8",
                      borderRadius: "10px",
                      padding: "8px 16px",
                      position: "relative",
                      width: "100%",
                      boxSizing: "border-box",
                      height: "50px",
                      backgroundColor: "#f5f5f5"
                    }}
                    value={values.email}
                    disabled
                />
              </Box>

              <Grid2 container gap="0 16px" wrap="nowrap">
                <Grid2 size={3} sx={{ display: 'flex', flexDirection: 'column', gap: '16px', width:'100%'}}>
                  <Box sx={{ padding: '0 16px' }}>
                    <LabelUI
                        htmlFor="phone"
                        text={t("phone")}
                        error={errors.phone && touched.phone || rejectedFields.includes('phone')} />
                  </Box>
                  <Field name="phone">
                    {({ field, form }:{field:any,form:any}) => (
                        <MuiPhoneInput
                            {...field}
                            value={field.value}
                            onChange={(phone) => {
                              form.setFieldValue(field.name, phone)
                              dispatch(removeRejectField('phone'))
                            }}
                            error={form.touched[field.name] && Boolean(form.errors[field.name]) || rejectedFields.includes(field.name)}
                            helperText={form.touched[field.name] && form.errors[field.name] || rejectedFields.includes(field.name)}
                        />
                    )}
                  </Field>
                  {errors.phone && touched.phone &&
                      <Box sx={{ padding: '0 16px' }}>
                        <Typography
                            variant="caption" sx={{ color: "#FF6954", fontSize: "14px" }}>
                          The field cannot be empty
                        </Typography>
                      </Box>
                  }
                </Grid2>
              </Grid2>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', }}>
                <Box sx={{ padding: '0 16px' }}>
                  <LabelUI
                      htmlFor="primary_contact_name"
                      text={t("kyb_prim_contact")}
                      error={errors.primary_contact_name && touched.primary_contact_name || rejectedFields.includes('primary_contact_name')} />
                </Box>

                <Field
                    name='primary_contact_name'
                    style={{
                      border: errors.primary_contact_name && touched.primary_contact_name || rejectedFields.includes('primary_contact_name') ? "1px solid #FF6954" : "1px solid #D8D8D8",
                      borderRadius: "10px",
                      padding: "8px 16px",
                      position: "relative",
                      width: "100%",
                      boxSizing: "border-box",
                      height: "50px",
                    }}
                    value={values.primary_contact_name}
                    onChange={(e: any) => {
                      setFieldValue('primary_contact_name', e.target.value)
                      dispatch(removeRejectField('primary_contact_name'))
                    }}
                />
                {errors.primary_contact_name && touched.primary_contact_name ? (
                    <Box sx={{ padding: '0 16px' }}>
                      <Typography
                          variant="caption" sx={{ color: "#FF6954", fontSize: "14px" }}>
                        {errors.primary_contact_name}
                      </Typography>
                    </Box>

                ) : null}
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', }}>
                <Box sx={{ padding: '0 16px' }}>
                  <LabelUI
                      htmlFor="position"
                      text={t("position")}
                      error={errors.position && touched.position || rejectedFields.includes('position')} />
                </Box>

                <Field
                    name='position'
                    style={{
                      border: errors.position && touched.position || rejectedFields.includes('position') ? "1px solid #FF6954" : "1px solid #D8D8D8",
                      borderRadius: "10px",
                      padding: "8px 16px",
                      position: "relative",
                      width: "100%",
                      boxSizing: "border-box",
                      height: "50px",
                    }}
                    value={values.position}
                    onChange={(e: any) => {
                      setFieldValue('position', e.target.value)
                      dispatch(removeRejectField('position'))
                    }}
                />
                {errors.position && touched.position ? (
                    <Box sx={{ padding: '0 16px' }}>
                      <Typography
                          variant="caption" sx={{ color: "#FF6954", fontSize: "14px" }}>
                        {errors.position}
                      </Typography>
                    </Box>

                ) : null}
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', gap: "16px" }}>
                <ButtonUI type="outline" text={t("back")} onClick={backHandler} />
                <ButtonUI type="default" text="Next" onClick={handleSubmit} />
              </Box>
            </Form>
          )
        }}

      </Formik >
  )
}

export default Step2