import {Accordion, AccordionDetails, AccordionSummary, Typography,} from "@mui/material";
import styles from "./styles";
import {useState} from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {StatisticsContent} from "./StatisticsContent";

interface Props {
  variant?: "accordion" | "default";
}

const Statistics = ({variant = "default",}: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  return (variant === "accordion" ? (
      <Accordion variant="outlined" sx={styles.container} expanded={isOpen}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              onClick={() => setIsOpen(!isOpen)}
              style={{ cursor: "pointer" }}
            />
          }
          sx={styles.header}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="h6">Statistics</Typography>
        </AccordionSummary>
        <AccordionDetails sx={styles.content}>
          <StatisticsContent variant={variant}/>
        </AccordionDetails>
      </Accordion>
    ) : (
      <StatisticsContent variant={variant}/>
    )
  );
};

export default Statistics;
