import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuth: localStorage.getItem('auth') || false,
  email: "",
  socket: null,
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload
    },

    auth: (state, action) => {
      localStorage.setItem('auth', action.payload)
      state.isAuth = action.payload
    },
  },
});

export const { setEmail, auth } = authSlice.actions;
export default authSlice.reducer