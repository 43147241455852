import {Avatar, Box, Button, Divider, List, ListItem, ListItemButton, ListItemIcon, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {setMenuItem} from "../../../../../app/slices/drawer";
import IconUI from "../../../../ui/icon";
import {CustomTooltip} from "../../../../ui";
import React from "react";
import {HeaderMenu} from "../../../../../shared/types/instances";
import {useDispatch, useSelector} from "react-redux";
import {auth} from "../../../../../app/slices/auth";
import {closeSocket} from "../../../../../app/slices/socketSlice";

export const ProfileBlock = ({handleMenuClose, handleMenuOpen, menu, open}: HeaderMenu) => {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.dashboard.user);

  const userFullName =`${user.first_name || ''} ${user.second_name || ''}`

  const logout = () => {
    localStorage.clear();
    dispatch(auth(false));
    dispatch(closeSocket());
    dispatch(setMenuItem(''))
    window.location.replace('/sign-in');
  };

  return (
      <CustomTooltip
          open={menu || open}
          onClose={handleMenuClose}

          title={
            <Box sx={{position: 'relative', transform: "translate(0px, 52px)"}}>
              <Box sx={{
                width: "24px",
                height: "13px",
                backgroundColor: "#ffffff",
                clipPath: "polygon(0 0, 24px 0, 12px 12px)",
                transform: "rotate(180deg)",
                position: 'absolute',
                right: "32px",
                top: "-12px"
              }}></Box>
              <Box sx={{
                boxShadow: "0px 0px 16px 0px #E5E5E5",
                backgroundColor: '#FFFFFF',
                borderRadius: "10px",
                width: '227px',
                color: "#2D2D2D",
                boxSizing: "border-box",
              }}>
                <List>

                  <Link to="/home/profile" style={{textDecoration: 'none'}}
                        onClick={() => dispatch(setMenuItem('/profile'))}>

                    <ListItem disablePadding>
                      <ListItemButton
                          disableRipple
                          sx={{
                            textDecoration: 'none',
                            padding: "8px 16px",
                            display: 'flex',
                            alignItems: 'center',
                            gap: "16px"
                          }}
                          onClick={handleMenuClose}
                      >
                        <ListItemIcon sx={{minWidth: "24px"}}>
                          <IconUI name="human"/>
                        </ListItemIcon>
                        <Typography variant="subtitle1" sx={{fontWeight: 500, color: "#2D2D2D"}}>
                          Profile
                        </Typography>
                      </ListItemButton>

                    </ListItem>
                  </Link>

                  <Link to="/home/profile/password" style={{textDecoration: 'none'}}>
                    <ListItem disablePadding>
                      <ListItemButton
                          disableRipple
                          sx={{
                            textDecoration: 'none',
                            padding: "8px 16px",
                            display: 'flex',
                            alignItems: 'center',
                            gap: "16px"
                          }}
                          onClick={handleMenuClose}
                      >
                        <ListItemIcon sx={{minWidth: "24px"}}>
                          <IconUI name="key"/>
                        </ListItemIcon>
                        <Typography
                            variant="subtitle1"
                            sx={{fontWeight: 500, color: "#2D2D2D"}}
                        >
                          Password
                        </Typography>
                      </ListItemButton>
                    </ListItem>
                  </Link>

                  <Divider flexItem sx={{margin: "8px 0"}}/>

                  <ListItem disablePadding>
                    <ListItemButton
                        disableRipple
                        sx={{
                          textDecoration: 'none',
                          padding: "8px 16px",
                          display: 'flex',
                          alignItems: 'center',
                          gap: "16px"
                        }}
                        onClick={() => logout()}
                    >
                      <ListItemIcon sx={{minWidth: "24px"}}>
                        <IconUI name="logout"/>
                      </ListItemIcon>
                      <Typography
                          variant="subtitle1"
                          sx={{fontWeight: 500, color: "#2D2D2D"}}
                      >
                        Logout
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                </List>

              </Box>
            </Box>

          }
      >
        <Button
            onClick={handleMenuOpen}
            disableRipple
            sx={{textTransform: 'none'}}
        >

          <Box sx={{display: 'flex', alignItems: 'center', gap: "8px"}}>
            <Box sx={{display: 'flex', alignItems: 'center', gap: "16px", width: '100%'}}>
              <Box sx={{flexGrow: 1}}>
                <Typography
                    variant="subtitle1"
                    sx={{fontWeight: 500, textAlign: 'right', color: "#2D2D2D"}}
                >
                  {userFullName.length > 23
                      ? userFullName.slice(0,23) + '...'
                      : userFullName
                  }
                </Typography>
                <Typography
                    variant="subtitle2"
                    sx={{textAlign: 'right', color: "#5F5F5F"}}
                >
                  {user.email}
                </Typography>
              </Box>
              <Avatar
                  src={user.avatar_url ? `https://app.iqtag.io${user.avatar_url}` : ''}
                  sx={{
                    '& img': {
                      maxWidth: '40px',
                      maxHeight: '40px',
                      width: '100%',
                      height: '100%'
                    }
                  }}
              />
            </Box>
            <Box sx={menu ? {transform: 'rotate(180deg)'} : {transform: 'rotate(0deg)'}}>
              <IconUI name="icon-down" size={8}/>
            </Box>
          </Box>

        </Button>
      </CustomTooltip>
  )
}