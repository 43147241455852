import {Box, Typography} from "@mui/material";
import {useState} from "react";
import CustomTabs from "../../Tabs";
import styles from "./styles";
import {useTranslation} from "react-i18next";
import {AnalyticsData} from "./AnalyticsData/AnalyticsData";


const Analytics = ({ menu }: { menu: 'dashboard' | 'products'; }) => {
  const [tab, setTab] = useState(0);

  const {t} = useTranslation()
  const tabs = [t("scanned"), t("verified")];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <Typography variant="h6">{t("analytics")}</Typography>
        <CustomTabs value={tab} handleChange={handleChange} tabs={tabs} />
      </Box>
      <Box sx={styles.content}>
        {tab === 0 ?
            <AnalyticsData type={"scanned"} menu={menu} tab={tab}/>
          :
            <AnalyticsData type={"verified"} menu={menu} tab={tab}/>
        }
      </Box >
    </Box >
  );
};

export default Analytics;