import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import Products from "../../api/repositories/products";
import {handleApiError} from "../../helpers/handleApiError";
import {toast} from "react-toastify";
import {getProduct} from "./products";

const initialState = {
  qrCodes: [],
  isLoading: false,
  qrInfo: {
    notificationIsGet: false,
    productId: '',
    productName: '',
    qrCodeId: '',
  },
  scrollToId: localStorage.getItem('scrollToId', ''),
};

export const addQrCodes = createAsyncThunk(
    "products/addQrCodes",
    async (payload) => {
      try {
        const response = await Products.add_qr_codes(
            {
              product_id: payload.productId,
              count : payload.qr_amount,
              color: payload.color,
              type : "ALL"
            });

        return response.data;
      } catch (error) {
        if(error.response.data.detail.Message === 'Dont have balance')
          toast.error("You don't have enough QR codes on your balance to purchase")
        else
          handleApiError(error)
      }
    },
);

const qrsSlice = createSlice({
  name: "qr",
  initialState,
  reducers: {
    updateQrStatus: (state, {payload}) => {
      const { qr_code_id, productId, productName, updatedFields } = payload;

      // Находим индекс объекта по ID
      const index = state.qrCodes.findIndex((qrCode) => qrCode._id === qr_code_id);

      if (index !== -1) {
        state.qrCodes[index] = { ...state.qrCodes[index], ...updatedFields };
        state.qrInfo = {
          qrCodeId: qr_code_id,
          productId,
          productName,
          notificationIsGet: true
        }
      }
    },
    notificationGet: (state) => {
      state.qrInfo.notificationIsGet = false
    },
    setScrollToId: (state, action) => {
      localStorage.setItem('scrollToId', action.payload)
      state.scrollToId = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
        .addCase(addQrCodes.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(addQrCodes.fulfilled, (state) => {
          state.isLoading = false;
        })
    builder
        .addCase(getProduct.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getProduct.fulfilled, (state, { payload}) => {
          if(payload?.Event && payload.Event.qr_codes?.length)
            state.qrCodes = [...payload.Event.qr_codes].reverse()
        })
  },
});

export const { updateQrStatus, notificationGet, setScrollToId } = qrsSlice.actions
export default qrsSlice.reducer;