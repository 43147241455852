import {createContext, ReactNode, useContext, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {ToastNotification} from "../PersonalArea/ToastNotification/ToastNotification";
import { notificationGet } from "../../../app/slices/qr";

const NotificationContext = createContext(null);

export const NotificationProvider = ({ children }: {children: ReactNode}) => {
  const dispatch = useDispatch();
  const qrInfo = useSelector((state: any) => state.qr.qrInfo);

  useEffect(() => {
    if (qrInfo.notificationIsGet) {
      toast(<ToastNotification type={"QR_READY"} productInfo={qrInfo} />, {
        autoClose: 10000, // Закроется через 10 секунд
        hideProgressBar: true,
      });

      const audio = new Audio("/assets/audio/notification.mp3");
      audio.play();

      dispatch(notificationGet());
    }
  }, [qrInfo.notificationIsGet]);

  return (
      <NotificationContext.Provider value={null}>
        {children}
      </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);
