import {Accordion, AccordionDetails, AccordionSummary, Box, Typography} from "@mui/material";
import {checkNoDataDescription, CustomTabPanel} from "../../../../../helpers";
import AnalyticHeader from "../AnalyticHeader";
import AnalyticChart from "../AnalyticChart";
import AnalyticMap from "../AnalyticMap";
import styles from "../styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NoData from "../../NoData/NoData";
import {useSelector} from "react-redux";
import {ObjectsData} from "../../../../../shared/types/instances";
import {useLocation} from "react-router";
import {AccordionMap} from "./Accordions/AccordionMap";
import {AccordionGraph} from "./Accordions/AccordionGraph";
import useAnalyticsData from "../../../../../shared/hooks/useAnalyticsData";

interface Props {
  type: 'scanned' | 'verified',
  menu: 'products' | 'dashboard',
  tab: number,
}

// const data = [
//   {name: "27.11", uv: 573},
//   {name: "28.11", uv: 436},
//   {name: "29.11", uv: 52},
//   {name: "30.11", uv: 87},
//   {name: "01.12", uv: 331},
//   {name: "02.12", uv: 645},
//   {name: "03.12", uv: 998},
//   {name: "04.12", uv: 637},
//   {name: "05.12", uv: 382},
//   {name: "06.12", uv: 144},
//   {name: "07.12", uv: 140},
//   {name: "08.12", uv: 743},
//   {name: "09.12", uv: 99},
//   {name: "10.12", uv: 147},
//   {name: "11.12", uv: 69},
//   {name: "12.12", uv: 317},
//   {name: "13.12", uv: 857},
//   {name: "14.12", uv: 281},
//   {name: "15.12", uv: 860},
//   {name: "16.12", uv: 475},
//   {name: "17.12", uv: 683},
//   {name: "18.12", uv: 190},
//   {name: "19.12", uv: 769},
//   {name: "20.12", uv: 381},
//   {name: "21.12", uv: 686},
//   {name: "22.12", uv: 946},
//   {name: "23.12", uv: 356},
//   {name: "24.12", uv: 492},
//   {name: "25.12", uv: 157},
//   {name: "26.12", uv: 542}
// ]

export const AnalyticsData = ({menu, type, tab}: Props) => {
  const analyticsView = useSelector((state: any) => state.analytics.analyticsView);
  const analyticsTime = useSelector((state: any) => state.analytics.analyticsTime);

  const statisticsDataProductTotal: ObjectsData = useSelector((state: any) => state.products.statisticsData['Total']);
  const statisticsDataDashboardTotal: ObjectsData = useSelector((state: any) => state.dashboard.statisticsData['Total']);

  const { pathname } = useLocation();

  const analyticsData = useAnalyticsData(type, menu)



  return (
      <>
        {analyticsData.length > 0 ?
            <Box sx={{ width: '100%'}}>
              <CustomTabPanel value={tab} index={tab}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '32px'}}>
                  <AnalyticHeader />
                  {analyticsView === "Graph"
                      ? (
                          <AnalyticChart
                              data={analyticsData.slice(analyticsData.length - analyticsTime)}
                              color={type === 'scanned' ? "#FF8800" : 'rgb(0, 192, 106)'}
                              type={type}
                          />
                      )
                      : (
                          <AnalyticMap
                              type={type}
                              place={menu}
                          />
                      )
                  }
                  <Accordion variant="outlined" sx={styles.accordion} defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={styles.accordion_summary}
                    >
                      <Typography variant="h6" sx={styles.title}>Total period amount</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ display: 'flex', gap: '64px', padding: '0 32px 32px 32px',}}>
                      <Box sx={styles.flex_center_box}>
                        <Box sx={{ height: "24px", backgroundColor: "#2D2D2D", width: "8px", borderRadius: "32px", }}></Box>
                        <Box >
                          <Typography variant="h6" sx={styles.title}>
                            {menu === 'dashboard'
                                ? String(statisticsDataDashboardTotal || 0)
                                : String(statisticsDataProductTotal || 0)
                            }
                          </Typography>
                          <Typography variant="caption" sx={styles.subtitle}>Total</Typography>
                        </Box>
                      </Box>
                      {analyticsView === "Graph"
                          ? <AccordionGraph
                              data={analyticsData}
                              analyticsTime={analyticsTime}
                              type={type}
                            />
                          : <AccordionMap
                              type={type}
                              menu={menu}
                          />
                      }
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </CustomTabPanel>
            </Box>
            :
            <NoData description={checkNoDataDescription(pathname)} />
        }
      </>
  )
}