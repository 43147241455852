import React from "react";
import {Navigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {closeSocket} from "../app/slices/socketSlice";

const ProtectedRoute = ({ children }: { children: React.ReactNode}) => {
  const isAuth = localStorage.getItem('access_token')
  const dispatch = useDispatch()

  if (isAuth === null) {
    dispatch(closeSocket());
    return <Navigate to="/sign-in" replace />;
  }

  return children;
};

export default ProtectedRoute;