import {styles} from "./styles";
import {Box} from "@mui/material";

interface Props {
  totalVerified: string | number;
  total: string | number;
  callPlaceIsProductTab?: boolean;
}

export const StatisticsBar = ({totalVerified, total, callPlaceIsProductTab = false} : Props) => {

  return (
      <Box sx={{
        ...styles.flexCenter,
        height: callPlaceIsProductTab ? '100%' : 'auto'
      }}>
        <Box
            sx={{
              ...styles.bar,
              '&::before': {
                content: '""',
                position: 'absolute',
                height: '8px',
                background: "linear-gradient(90deg, #FF8800 0%, #FF6954 100%)",
                top: '-45%',
                left:'0',
                zIndex: '0',
                borderRadius: "10px",
                width: `${
                    isNaN(
                        (Number(totalVerified) /
                            Number(total)) *
                        96
                    )
                        ? 0
                        : (Number(totalVerified) /
                            Number(total)) *
                        96
                }px`,
              },
            }}
        >
        </Box>
      </Box>
  )
}