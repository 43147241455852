import "react-step-progress-bar/styles.css";

import {Accordion, AccordionDetails, AccordionSummary, Box, Grid2, Typography} from "@mui/material";
import {ProgressBar, Step} from "react-step-progress-bar";
import {setKybStatus, setStatus} from "../../../../../app/slices/kyb";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";

import Content from "../Content/Content";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormVariant from "./forms";
import IconUI from "../../../../ui/icon";
import styles from "./styles";
import {useTranslation} from "react-i18next";
import Logo from "../Logo/Logo";
import CustomTabs from "../../../Tabs/Tabs";
import {useWindowSize} from "@uidotdev/usehooks";

const Verification = () => {
  const dispatch = useDispatch();
  const { kyb_status, message_reject, rejectedSteps} = useSelector((state: any) => state.kyb);
  const isRejected = useSelector((state: any) => state.kyb.isRejected);

  const {t} = useTranslation();
  const {width} = useWindowSize();

  const isMobile = width && width <= 1024;
  const [kybOpen, setKybOpen] = useState(true);
  const [tab, setTab] = useState(0)
  const [page, setPage] = useState(1);

  const steps = ["first_tier", "second_tier", "third_tier", "fourth_tier", "pending"];
  const hint = [
    t("company"),
    "Identity",
    "Address",
    "Documents"
  ];

  const tabs =  [
      { label: "KYB", disabled: false },
      { label: "Logotype", disabled: false },
  ];

  const handleArrow = () => {
    switch (kyb_status) {
      case 'first_tier':
        return 'Company';
      case 'second_tier':
        return 'Identity';
      case 'third_tier':
        return "Address";
      case 'fourth_tier':
        return "Documents";
      case 'pending':
        return "Documents";
      default:
    }
  };


  useEffect(() => {
    try {
      // @ts-ignore
      dispatch(setKybStatus())
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleVariant = () => {
    if (kyb_status === 'first_tier') {
      return t("comp_information");
    } else if (kyb_status === 'second_tier') {
      return 'Identity verification';
    } else if (kyb_status === 'third_tier') {
      return 'Registration address';
    } else if (kyb_status === 'fourth_tier') {
      return 'Company Documents';
    } else if (kyb_status === 'pending') {
      return 'Company Documents';
    }
  };

  const handelPercent = () => {
    if (kyb_status === 'first_tier') {
      return 0;
    } else if (kyb_status === 'second_tier') {
      return 25;
    } else if (kyb_status === 'third_tier') {
      return 50;
    } else if (kyb_status === 'fourth_tier') {
      return 75;
    } else if (kyb_status === 'pending' || kyb_status === 'approved' || kyb_status === 'rejected') {
      return 100;
    }
  };

  const handleStepUp = () => {
    setPage(page + 1);
  };

  const handleStepDown = () => {
    setPage(page - 1);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    console.log(newValue)
    setTab(newValue);
  };

  const handleStepChange = (index: number) => {
    dispatch(setStatus(steps[index]))
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '32px', flexShrink:'1', width: '100%' }}>
      <Accordion
        variant="outlined"
        sx={styles.container}
        expanded={kybOpen}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon onClick={() => setKybOpen(!kybOpen)} style={{cursor: 'pointer'}} />}
            sx={{
            margin: "0 !important",
            minHeight: "32px !important",
            "& .MuiAccordionSummary-content": {
              margin: "0 !important",
            },
            cursor: 'default'
          }}
          aria-controls="panel1-content"
          id="panel1-header"
        >

          <Box sx={{ margin: "0 !important", cursor: 'default' }}>
            <Typography variant="h6">{t("kyb")}</Typography>
          </Box>

        </AccordionSummary>
        {isMobile && (
            <Box sx={{marginBottom:"32px", width:"60%", padding:"0 16px"}}>
              <CustomTabs
                  value={tab}
                  handleChange={handleChange}
                  tabs={tabs}
                />
            </Box>
        )}
        <AccordionDetails sx={styles.expand}>
          {tab === 1 ? <Logo/> : 
            kyb_status === 'approved' ? (
              <>
                <Box sx={{ paddingTop: '32px' }}>
                  <Typography variant="h6" sx={{ padding: `${isMobile ? '0 16px': '0 32px'}` }}>Verification</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '32px', 
                  padding: `${isMobile ? '16px': '32px'}`, 
                  etterSpacing: '1%', lineHeight: '24px' }}>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                    <Typography variant="subtitle2" sx={{
                      lineHeight: "24px",
                      letterSpacing: "0.01em",
                      color: "#5F5F5F",
                    }}>
                      Fill in your company details. We will review them as soon as possible.
                    </Typography>

                    <Box sx={{ backgroundColor: "#00C06A", borderRadius: "10px", padding: "12px 16px", display: "flex", gap: "16px", alignItems: "center" }}>
                      <IconUI name="check_white" />
                      <Typography variant="subtitle1" sx={{ color: "#FFF", fontWeight: "500" }}>{t("kyb_status")} {t("verified")}</Typography>
                    </Box>

                    <Typography variant="subtitle2" sx={{
                      lineHeight: "24px",
                      letterSpacing: "0.01em",
                      color: "#5F5F5F",
                    }}>
                      Your company has been successfully verified. You’re all set to access the full range of platform features.
                    </Typography>
                  </Box >
                </Box>
              </>

            )
              : (
                <>
                  <Box sx={{ paddingTop: "32px" }}>
                    <Typography variant="h6" sx={{ padding: `${isMobile ? '0 16px': '0 32px'}` }}>Verification</Typography>
                  </Box>

                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '32px', 
                    padding: `${isMobile ? '16px': '32px'}`, letterSpacing: '1%', lineHeight: '24px' }}>
                    <Typography variant="caption" sx={{ color: '#5F5F5F', fontSize: '14px' }}>
                      {t("kyb_stage")}
                    </Typography>

                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '28px' }}>
                      <Grid2 container sx={{ padding: `${isMobile ? '0 16px': '0 32px'}`}}>
                        {hint.map((item, index) => (
                          <Grid2 size={3} key={index}>
                            <Box sx={{}}>
                              <Typography variant="subtitle2" sx={{ textAlign: 'center', fontSize: '14px' }}>{item}</Typography>
                            </Box>
                            {
                              item === handleArrow() ?
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                  <IconUI name="icon-down" size={8} />
                                </Box>
                                : null
                            }
                          </Grid2>
                        ))}
                      </Grid2>

                      <Box sx={{ padding: "0 32px" }}>
                        <ProgressBar
                          percent={handelPercent()}
                          filledBackground="linear-gradient(90deg, #FF8800 0%, #FF6954 100%)"
                        >
                          {steps.map((step, index) => (
                            <Step transition="scale" key={index}>
                              {({ accomplished }: any) => (
                                isRejected ? (
                                  <Box
                                      // onClick={() => handleStepChange(index)}
                                      sx={{
                                        ...(step === rejectedSteps.find((item:any) => item === step) ? styles.boxRejectedUncomplete : {}),
                                        ...(step !== rejectedSteps.find((item:any) => item === step) ? styles.boxRejectedComplete : {}),
                                      }}
                                  >
                                    {index + 1}
                                  </Box>
                                ) : (
                                  <Box
                                      // onClick={() => handleStepChange(index)}
                                      sx={
                                        step === kyb_status
                                          ? styles.boxComplete
                                          : styles.boxUncomplete

                                      }
                                  >
                                    {index + 1}
                                  </Box>
                                )
                              )}
                            </Step>
                          ))}
                        </ProgressBar>
                      </Box>
                    </Box>

                    {isRejected &&
                      <Box sx={styles.rejectBlock}>
                        <Box sx={{...styles.info, ...styles.infoRejected}}>
                          <IconUI name="warning" />
                          <Typography variant="subtitle1" sx={styles.title}>{t("kyb_status")} Rejected</Typography>
                        </Box>

                        <Typography sx={styles.description}>
                          {message_reject}
                        </Typography>
                      </Box>
                    }

                    <Box>
                      <Typography
                        variant="h6"
                        sx={{ padding: "0 32px", textAlign: 'center' }}>
                        {handleVariant()}
                      </Typography>
                    </Box>

                    <FormVariant
                      handleStepUp={handleStepUp}
                      handleStepDown={handleStepDown}
                    />
                  </Box>
                </>
              )
          }
        </AccordionDetails>
      </Accordion >

      {kyb_status === 'approved' && (
        <Content />
      )}
    </Box>
  );
};

export default Verification;