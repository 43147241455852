import styles from "../styles";
import {Box, ClickAwayListener, Tooltip, tooltipClasses, Typography} from "@mui/material";
import IconUI from "../../../../ui/icon";
import {StatisticsBar} from "../../../StatisticsBar/StatisticsBar";
import {styled} from "@mui/system";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import { ObjectsData } from "../../../../../shared/types/instances";
import {useSelector} from "react-redux";

export interface ItemProps {
  keyValue: string,
  value: number | string,
  index: number,
  type: "accordion" | "default",
  isMobile: number | boolean | null,
  swipeStart: boolean,
}

export const StatisticsItem = ({keyValue, value, index, type, isMobile, swipeStart}: ItemProps) => {
  const { t } = useTranslation();

  const statisticsDataProduct: ObjectsData = useSelector(
      (state: any) => state.products.statisticsData
  );

  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => setOpen(false);
  const handleTooltipOpen = () => setOpen(true);

  const helpStatisticsAccordionText = [
    t("statistics_text.registered"),
    t("statistics_text.verified"),
    t("statistics_text.reward"),
  ];

  const helpStatisticText = [
    "Total QR codes",
    "Total product authenticity verified",
    "Total number of open QR codes scanned",
  ];

  const statisticsText = [t("total"), t("total_verified"), t("total_scanned")];

  const tooltipText =  (
      type === "accordion"
          ? helpStatisticsAccordionText
          : helpStatisticText
  )

  const tooltipRender = (isMobile: ItemProps['isMobile']) => {
    if(isMobile) {
      return (
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <AnimatedTooltip
                  title={tooltipText[index]}
                  onClose={handleTooltipClose}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  open={open}

              >
                <Box onClick={handleTooltipOpen}>
                  <IconUI
                      name="hint"
                      style={{ cursor: "pointer" }}
                  />
                </Box>
              </AnimatedTooltip>
            </div>
          </ClickAwayListener>
      )
    } else {
      return  (
        <AnimatedTooltip title={tooltipText[index]}>
          <Box>
            <IconUI name="hint" style={{ cursor: "pointer" }}/>
          </Box>
        </AnimatedTooltip>
      )
    }
  }

  useEffect(() => {
    if(swipeStart)
      handleTooltipClose()
  }, [swipeStart]);

  return ( type === 'accordion' ? (
      <Box sx={styles.item}>
        <Box sx={styles.card}>
          <Box sx={styles.decorative}>
          </Box>
          <Box>
            <Typography variant="h6" sx={styles.title}>
              {value + (keyValue === "reward" ? " $" : "")}
            </Typography>
            <Typography
                variant="caption"
                sx={styles.subtitle}
            >
              {keyValue.charAt(0).toUpperCase() +
                  keyValue.slice(1) +
                  (keyValue === "reward" ? " (Balance)" : "")
              }
            </Typography>
          </Box>
        </Box>

        <Box>
          {tooltipRender(isMobile)}
        </Box>
      </Box>
    ) : (
      <Box sx={styles.item}>
        <Box sx={styles.card}>
          <Box sx={styles.decorative}>
          </Box>
          <Box>
            <Typography variant="h6" sx={styles.title}>
              {new Intl.NumberFormat("en-US").format(
                  Number(value)
              )}
            </Typography>
            <Typography variant="caption" sx={styles.subtitle}>
              {statisticsText[index]}
            </Typography>
          </Box>
        </Box>

        <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.25rem",
              alignItems: "flex-end",
            }}
        >
          <Box>
            {tooltipRender(isMobile)}
          </Box>

          {keyValue === "Total Verified" && (
              <StatisticsBar
                  total={statisticsDataProduct["Total"]}
                  totalVerified={statisticsDataProduct["Total Verified"]}
              />
          )}
        </Box>
      </Box>
    )
  )
}


const AnimatedTooltip = styled(({ className, ...props }: any) => (
    <Tooltip
        classes={{ popper: className }}
        enterDelay={500}
        leaveDelay={200}
        arrow
        placement="top"
        disableFocusListener
        {...props}
    />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffffff",
    color: "#000000",
    boxShadow: "0px 4px 8px rgba(59, 58, 58, 0.2)",
    fontSize: "0.9rem",
    fontWeight: 300,
    opacity: 0,
    padding: 16,
    borderRadius: "16px",
    transition: "opacity 0.3s ease-in-out",
  },
  [`& .${tooltipClasses.popper}`]: {
    "&[data-popper-placement*='top'] .MuiTooltip-arrow": {
      color: "#ffffff",
      transform: "rotate(180deg)",
    },
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#ffffff",
  },
  "&[data-state='entering'], &[data-state='entered']": {
    [`& .${tooltipClasses.tooltip}`]: {
      opacity: 1,
    },
  },
}));