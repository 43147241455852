import {useWindowSize} from "@uidotdev/usehooks";
import {Box} from "@mui/material";
import styles from "./styles";
import {StatisticsItem} from "./StatisticsItem/StatisticsItem";
import {ObjectsData} from "../../../../shared/types/instances";
import {useSelector} from "react-redux";

import {Swiper as SwiperReact, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import {useState} from "react";

interface Props {
  variant: "accordion" | "default";
}

export const StatisticsContent = ({variant}: Props) => {
  const { width } = useWindowSize();
  const isMobile = width && width < 1024;
  const [swipeStart, setSwipeStart] = useState(false);

  const statisticsDataProduct: ObjectsData = useSelector(
      (state: any) => state.products.statisticsData
  );
  const statisticsDataReferral: ObjectsData = useSelector(
      (state:any) => state.referral.statisticsData
  )

  const data =  (
      variant === "accordion"
          ? statisticsDataReferral
          : statisticsDataProduct
  )


  return (isMobile ? (
        <Box sx={styles.swiper}>
          <SwiperReact
              modules={[Pagination]}
              spaceBetween={50}
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
              onSlideChangeTransitionStart={() => setSwipeStart(true)} // Начало свайпа
              onSlideChangeTransitionEnd={() => setSwipeStart(false)} // Конец свайпа
          >
            {Object.entries(data).map(
                ([key, value], index) => (
                    <Box sx={{ width: "100%" }} key={index}>
                      <SwiperSlide key={key + index}>
                        <StatisticsItem
                            keyValue={key}
                            value={value}
                            index={index}
                            key={key}
                            type={variant}
                            isMobile={isMobile}
                            swipeStart={swipeStart}
                        />
                      </SwiperSlide>
                    </Box>
                ))
            }
          </SwiperReact>
        </Box>
    ) : (
        <Box sx={styles.content}>
          {Object.entries(data).map(
              ([key, value], index) => (
                  <StatisticsItem
                      keyValue={key}
                      value={value}
                      index={index}
                      key={key}
                      type={variant}
                      isMobile={isMobile}
                      swipeStart={swipeStart}
                  />
              )
          )}
        </Box>
    ))
}